import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import {PageNav, MobileNav} from './components/Nav.js';
import './App.css';
import Container from 'react-bootstrap/Container';
import MainPage from './components/MainPage.js';
import ContactPage from './components/ContactPage.js';
import AboutPage from './components/AboutPage.js';
import MyWorkPage from './components/MyWorkPage.js';

/*
    Top level App Component for site.
*/
//Routes for page navigation, when new page views are added, Routes for the Component Pages must be added
function App() {
  const Responsive = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    return (
      <>
        {isTabletOrMobile ? <MobileNav/> : <PageNav/>}
        <Container style={{"width":"100vw", "maxWidth":"100vw", "padding":"0px", "textAlign":"center", "display":"inline-block"}}>
           <Routes>
             <Route path="/" element={<MainPage isTabletOrMobile={isTabletOrMobile} isPortrait={isPortrait}/>} ></Route>
             <Route path="/home" element={<MainPage isTabletOrMobile={isTabletOrMobile} isPortrait={isPortrait}/>} />
             <Route exact path="/myWork" element={<MyWorkPage isTabletOrMobile={isTabletOrMobile} isPortrait={isPortrait}/>} />
             <Route exact path="/contact" element={<ContactPage isTabletOrMobile={isTabletOrMobile} isPortrait={isPortrait}/>} />
             <Route exact path="/about" element={<AboutPage isTabletOrMobile={isTabletOrMobile} isPortrait={isPortrait}/>} />
           </Routes>
         </Container>
      </>
    );
  }

  return (
    <div style={{"width":"100vw","maxWidth":"100vw"}}>
      <BrowserRouter>
        <Responsive/>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, {useState} from "react";
import PageFooter from './PageFooter.js';
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/base";
import Modal from 'react-modal';
import { IoCloseCircleSharp } from 'react-icons/io5';

function MyWorkPage(props) {

  const [modalOpen, setModalOpen] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);
  const [filter, setFilter] = useState('animals');

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dennyjomiller98"
    }
  });

  function openModal(url) {
    setModalOpen(true);
    setModalUrl(url);
  }
  function closeModal() {
    setModalOpen(false);
  }

  const MyWorkDesktop = () => {
    return(
      <>
        <div style={{"width":"100vw", "height":"50vh", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "backgroundImage":"url(https://res.cloudinary.com/dennyjomiller98/image/upload/v1683561432/whale.jpg)", "backgroundSize":"cover", "backgroundPosition": "center", "backgroundRepeat": "no-repeat"}}></div>
        <div className="helv" style={{"textAlign":"center", "width":"80vw", "maxWidth":"80vw", "display":"inline-block", "paddingBottom":"10vh", "paddingTop":"5vh"}}>
          <h2 className={"font"}>My Work</h2>
          <div style={{"display":"flex"}} className={"font"}>
            <div className={filter === "all" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('all')}>All</div>
            <div className={filter === "animals" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('animals')}>Animals</div>
            <div className={filter === "beach" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('beach')}>Ocean</div>
            <div className={filter === "insects" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('insects')}>Insects</div>
            <div className={filter === "people" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('people')}>People</div>
            <div className={filter === "book" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('book')}>Book Covers</div>
            <div className={filter === "card" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('card')}>Festive</div>
            <div className={filter === "dtiys" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('dtiys')}>DTIYS</div>
          </div>

          <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={{"maxWidth":"100vw", "maxHeight":"100vh"}}
          >
            <div className={"hover"} style={{"maxWidth":"100%", "height":"100%", "width":"100%", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "backgroundImage":`url(${modalUrl})`, "backgroundSize":"contain", "backgroundPosition": "center", "backgroundRepeat": "no-repeat"}}>
              <IoCloseCircleSharp onClick={closeModal} size={'3em'} style={{"float":"right", "marginRight":"1%"}}/>
            </div>
          </Modal>

          <div style={{"display":filter==="animals" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Animals</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562582/work/animals/bearPink.jpg')}>
                  <AdvancedImage
                    key={"work/animals/bearPink"}
                    cldImg={cld.image("work/animals/bearPink")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562592/work/animals/mouse.jpg')}>
                  <AdvancedImage
                    key={"work/animals/mouse"}
                    cldImg={cld.image("work/animals/mouse")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562587/work/animals/badger.jpg')}>
                  <AdvancedImage
                    key={"work/animals/badger"}
                    cldImg={cld.image("work/animals/badger")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562583/work/animals/sunflower.jpg')}>
                  <AdvancedImage
                    key={"work/animals/sunflower"}
                    cldImg={cld.image("work/animals/sunflower")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562587/work/animals/basset.jpg')}>
                  <AdvancedImage
                    key={"work/animals/basset"}
                    cldImg={cld.image("work/animals/basset")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562580/work/animals/birdhouse.jpg')}>
                  <AdvancedImage
                    key={"work/animals/birdhouse"}
                    cldImg={cld.image("work/animals/birdhouse")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562585/work/animals/eye.jpg')}>
                  <AdvancedImage
                    key={"work/animals/eye"}
                    cldImg={cld.image("work/animals/eye")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562595/work/animals/sprout.jpg')}>
                  <AdvancedImage
                    key={"work/animals/sprout"}
                    cldImg={cld.image("work/animals/sprout")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562594/work/animals/lion.jpg')}>
                  <AdvancedImage
                    key={"work/animals/lion"}
                    cldImg={cld.image("work/animals/lion")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562592/work/animals/candle.jpg')}>
                  <AdvancedImage
                    key={"work/animals/candle"}
                    cldImg={cld.image("work/animals/candle")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562590/work/animals/sweet.jpg')}>
                  <AdvancedImage
                    key={"work/animals/sweet"}
                    cldImg={cld.image("work/animals/sweet")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683562593/work/animals/garden.jpg')}>
                  <AdvancedImage
                    key={"work/animals/garden"}
                    cldImg={cld.image("work/animals/garden")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="beach" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Ocean</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567624/work/beach/boat.jpg')}>
                  <AdvancedImage
                    key={"work/beach/boat"}
                    cldImg={cld.image("work/beach/boat")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567627/work/beach/ducks.jpg')}>
                  <AdvancedImage
                    key={"work/beach/ducks"}
                    cldImg={cld.image("work/beach/ducks")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567626/work/beach/lighthouse.jpg')}>
                  <AdvancedImage
                    key={"work/beach/lighthouse"}
                    cldImg={cld.image("work/beach/lighthouse")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567628/work/beach/seagull.jpg')}>
                  <AdvancedImage
                    key={"work/beach/seagull"}
                    cldImg={cld.image("work/beach/seagull")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567624/work/beach/seals.jpg')}>
                  <AdvancedImage
                    key={"work/beach/seals"}
                    cldImg={cld.image("work/beach/seals")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567627/work/beach/whaleBoat.jpg')}>
                  <AdvancedImage
                    key={"work/beach/whaleBoat"}
                    cldImg={cld.image("work/beach/whaleBoat")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="insects" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Insects</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567878/work/insects/snail.jpg')}>
                  <AdvancedImage
                    key={"work/insects/snail"}
                    cldImg={cld.image("work/insects/snail")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567879/work/insects/rhubarb.jpg')}>
                  <AdvancedImage
                    key={"work/insects/rhubarb"}
                    cldImg={cld.image("work/insects/rhubarb")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567879/work/insects/ladybug.jpg')}>
                  <AdvancedImage
                    key={"work/insects/ladybug"}
                    cldImg={cld.image("work/insects/ladybug")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567878/work/insects/bulb.jpg')}>
                  <AdvancedImage
                    key={"work/insects/bulb"}
                    cldImg={cld.image("work/insects/bulb")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567878/work/insects/caterpillar.jpg')}>
                  <AdvancedImage
                    key={"work/insects/caterpillar"}
                    cldImg={cld.image("work/insects/caterpillar")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683567879/work/insects/bee.jpg')}>
                  <AdvancedImage
                    key={"work/insects/bee"}
                    cldImg={cld.image("work/insects/bee")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="people" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>People</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683568382/work/people/secretGarden.jpg')}>
                  <AdvancedImage
                    key={"work/people/secretGarden"}
                    cldImg={cld.image("work/people/secretGarden")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683568379/work/people/strawberry.jpg')}>
                  <AdvancedImage
                    key={"work/people/strawberry"}
                    cldImg={cld.image("work/people/strawberry")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683568380/work/people/witch.jpg')}>
                  <AdvancedImage
                    key={"work/people/witch"}
                    cldImg={cld.image("work/people/witch")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683568381/work/people/potion.jpg')}>
                  <AdvancedImage
                    key={"work/people/potion"}
                    cldImg={cld.image("work/people/potion")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683568385/work/people/cosmos.jpg')}>
                  <AdvancedImage
                    key={"work/people/cosmos"}
                    cldImg={cld.image("work/people/cosmos")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683568384/work/people/mask.jpg')}>
                  <AdvancedImage
                    key={"work/people/mask"}
                    cldImg={cld.image("work/people/mask")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="book" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Book Covers</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683569098/work/book/redRidingHood.jpg')}>
                  <AdvancedImage
                    key={"work/book/redRidingHood"}
                    cldImg={cld.image("work/book/redRidingHood")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683569100/work/book/jackAndBeanstalk.jpg')}>
                  <AdvancedImage
                    key={"work/book/jackAndBeanstalk"}
                    cldImg={cld.image("work/book/jackAndBeanstalk")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683569099/work/book/aliceInWonderland.jpg')}>
                  <AdvancedImage
                    key={"work/book/aliceInWonderland"}
                    cldImg={cld.image("work/book/aliceInWonderland")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="card" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Festive</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570361/work/festive/coral.jpg')}>
                  <AdvancedImage
                    key={"work/festive/coral"}
                    cldImg={cld.image("work/festive/coral")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570362/work/festive/easter.jpg')}>
                  <AdvancedImage
                    key={"work/festive/easter"}
                    cldImg={cld.image("work/festive/easter")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570362/work/festive/spooky.jpg')}>
                  <AdvancedImage
                    key={"work/festive/spooky"}
                    cldImg={cld.image("work/festive/spooky")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570362/work/festive/fireside.jpg')}>
                  <AdvancedImage
                    key={"work/festive/fireside"}
                    cldImg={cld.image("work/festive/fireside")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570362/work/festive/reindeer.jpg')}>
                  <AdvancedImage
                    key={"work/festive/reindeer"}
                    cldImg={cld.image("work/festive/reindeer")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570362/work/festive/hedgehog.jpg')}>
                  <AdvancedImage
                    key={"work/festive/hedgehog"}
                    cldImg={cld.image("work/festive/hedgehog")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570362/work/festive/snowRabbits.jpg')}>
                  <AdvancedImage
                    key={"work/festive/snowRabbits"}
                    cldImg={cld.image("work/festive/snowRabbits")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="dtiys" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>DTIYS</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570975/work/dtiys/gletidtiys.jpg')}>
                  <AdvancedImage
                    key={"work/dtiys/gletidtiys"}
                    cldImg={cld.image("work/dtiys/gletidtiys")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570976/work/dtiys/nina.jpg')}>
                  <AdvancedImage
                    key={"work/dtiys/nina"}
                    cldImg={cld.image("work/dtiys/nina")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570973/work/dtiys/zovedtiys.jpg')}>
                  <AdvancedImage
                    key={"work/dtiys/zovedtiys"}
                    cldImg={cld.image("work/dtiys/zovedtiys")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570971/work/dtiys/inkyDinky.jpg')}>
                  <AdvancedImage
                    key={"work/dtiys/inkyDinky"}
                    cldImg={cld.image("work/dtiys/inkyDinky")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570974/work/dtiys/aliceFlower.jpg')}>
                  <AdvancedImage
                    key={"work/dtiys/aliceFlower"}
                    cldImg={cld.image("work/dtiys/aliceFlower")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570976/work/dtiys/fawa.jpg')}>
                  <AdvancedImage
                    key={"work/dtiys/fawa"}
                    cldImg={cld.image("work/dtiys/fawa")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570974/work/dtiys/elsabater.jpg')}>
                  <AdvancedImage
                    key={"work/dtiys/elsabater"}
                    cldImg={cld.image("work/dtiys/elsabater")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div onClick={() => openModal('https://res.cloudinary.com/dennyjomiller98/image/upload/v1683570977/work/dtiys/mossy.jpg')}>
                  <AdvancedImage
                    key={"work/dtiys/mossy"}
                    cldImg={cld.image("work/dtiys/mossy")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div style={{"height":"100vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
          <div style={{"width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
            <h2 className={"font"}>Like what you see?</h2>
            <p className={"font2"}>If you'd like to collaborate, please feel free to get in touch using the link below. I will aim to get back to you ASAP!</p>
            <a href="../contact" className={"homePageButton"}>
              Get in touch
            </a>
          </div>
          <div style={{"height":"50vh", "width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
            <div style={{"maxheight":"100%", "padding":"2%"}}>
               <AdvancedImage
                 key={"work/people/strawberry"}
                 cldImg={cld.image("work/people/strawberry")}
                 plugins={[lazyload(), placeholder("predominant-color")]}
                 style={{"maxWidth":"99%", "borderRadius":"5px","maxHeight":"100%", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
               />
            </div>
          </div>
        </div>
        <PageFooter isTabletOrMobile={props.isTabletOrMobile} />
      </>
    );
  }

  const MyWorkMobile = () => {
    return(
      <>
        {props.isPortrait ?
          <div style={{"width":"100vw", "height":"30vh", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "backgroundImage":"url(https://res.cloudinary.com/dennyjomiller98/image/upload/v1683561432/whale.jpg)", "backgroundSize":"cover", "backgroundPosition": "center", "backgroundRepeat": "no-repeat"}}></div>
          :
          <div style={{"width":"100vw", "height":"50vh", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "backgroundImage":"url(https://res.cloudinary.com/dennyjomiller98/image/upload/v1683561432/whale.jpg)", "backgroundSize":"cover", "backgroundPosition": "center", "backgroundRepeat": "no-repeat"}}></div>
        }
        <div className="helv" style={{"textAlign":"center", "width":"80vw", "maxWidth":"80vw", "display":"inline-block", "paddingBottom":"10vh", "paddingTop":"5vh"}}>
          <h2 className={"font"}>My Work</h2>
          {props.isPortrait ?
            <div>
              <div style={{"display":"inline-block"}} className={"font"}>
                <div>
                  <div className={filter === "all" ? "selected hover": "hover"} style={{"display":"inline"}} onClick={() => setFilter('all')}>All</div>
                  &nbsp;&#183;&nbsp;
                  <div className={filter === "animals" ? "selected hover": "hover"} style={{"display":"inline"}} onClick={() => setFilter('animals')}>Animals</div>
                  &nbsp;&#183;&nbsp;
                  <div className={filter === "beach" ? "selected hover": "hover"} style={{"display":"inline"}} onClick={() => setFilter('beach')}>Ocean</div>
                  &nbsp;&#183;&nbsp;
                  <div className={filter === "insects" ? "selected hover": "hover"} style={{"display":"inline"}} onClick={() => setFilter('insects')}>Insects</div>
                  <br/>
                  <div className={filter === "people" ? "selected hover": "hover"} style={{"display":"inline"}} onClick={() => setFilter('people')}>People</div>
                  &nbsp;&#183;&nbsp;
                  <div className={filter === "book" ? "selected hover": "hover"} style={{"display":"inline"}} onClick={() => setFilter('book')}>Book Covers</div>
                  &nbsp;&#183;&nbsp;
                  <div className={filter === "card" ? "selected hover": "hover"} style={{"display":"inline"}} onClick={() => setFilter('card')}>Festive</div>
                  &nbsp;&#183;&nbsp;
                  <div className={filter === "dtiys" ? "selected hover": "hover"} style={{"display":"inline"}} onClick={() => setFilter('dtiys')}>DTIYS</div>
                </div>
              </div>
            </div>
            :
            <div style={{"display":"flex"}} className={"font"}>
              <div style={{"display":"flex"}}>
                <div className={filter === "all" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('all')}>All</div>
                <div className={filter === "animals" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('animals')}>Animals</div>
                <div className={filter === "beach" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('beach')}>Ocean</div>
                <div className={filter === "insects" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('insects')}>Insects</div>
              </div>
              <br/>
              <div style={{"display":"flex"}}>
                <div className={filter === "people" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('people')}>People</div>
                <div className={filter === "book" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('book')}>Book Covers</div>
                <div className={filter === "card" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('card')}>Festive</div>
                <div className={filter === "dtiys" ? "selected hover": "hover"} style={{"width":"10vw"}} onClick={() => setFilter('dtiys')}>DTIYS</div>
              </div>
            </div>
          }


          <div style={{"display":filter==="animals" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Animals</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/bearPink"}
                    cldImg={cld.image("work/animals/bearPink")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/mouse"}
                    cldImg={cld.image("work/animals/mouse")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/badger"}
                    cldImg={cld.image("work/animals/badger")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/sunflower"}
                    cldImg={cld.image("work/animals/sunflower")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/basset"}
                    cldImg={cld.image("work/animals/basset")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/birdhouse"}
                    cldImg={cld.image("work/animals/birdhouse")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/eye"}
                    cldImg={cld.image("work/animals/eye")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/sprout"}
                    cldImg={cld.image("work/animals/sprout")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/lion"}
                    cldImg={cld.image("work/animals/lion")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/candle"}
                    cldImg={cld.image("work/animals/candle")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/sweet"}
                    cldImg={cld.image("work/animals/sweet")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/animals/garden"}
                    cldImg={cld.image("work/animals/garden")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="beach" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Ocean</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/beach/boat"}
                    cldImg={cld.image("work/beach/boat")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/beach/ducks"}
                    cldImg={cld.image("work/beach/ducks")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/beach/lighthouse"}
                    cldImg={cld.image("work/beach/lighthouse")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/beach/seagull"}
                    cldImg={cld.image("work/beach/seagull")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/beach/seals"}
                    cldImg={cld.image("work/beach/seals")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/beach/whaleBoat"}
                    cldImg={cld.image("work/beach/whaleBoat")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="insects" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Insects</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/insects/snail"}
                    cldImg={cld.image("work/insects/snail")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/insects/rhubarb"}
                    cldImg={cld.image("work/insects/rhubarb")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/insects/ladybug"}
                    cldImg={cld.image("work/insects/ladybug")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/insects/bulb"}
                    cldImg={cld.image("work/insects/bulb")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/insects/caterpillar"}
                    cldImg={cld.image("work/insects/caterpillar")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/insects/bee"}
                    cldImg={cld.image("work/insects/bee")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="people" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>People</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/people/secretGarden"}
                    cldImg={cld.image("work/people/secretGarden")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/people/strawberry"}
                    cldImg={cld.image("work/people/strawberry")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/people/witch"}
                    cldImg={cld.image("work/people/witch")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/people/potion"}
                    cldImg={cld.image("work/people/potion")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/people/cosmos"}
                    cldImg={cld.image("work/people/cosmos")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/people/mask"}
                    cldImg={cld.image("work/people/mask")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="book" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Book Covers</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/book/redRidingHood"}
                    cldImg={cld.image("work/book/redRidingHood")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/book/jackAndBeanstalk"}
                    cldImg={cld.image("work/book/jackAndBeanstalk")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/book/aliceInWonderland"}
                    cldImg={cld.image("work/book/aliceInWonderland")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="card" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>Festive</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/festive/coral"}
                    cldImg={cld.image("work/festive/coral")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/festive/easter"}
                    cldImg={cld.image("work/festive/easter")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/festive/spooky"}
                    cldImg={cld.image("work/festive/spooky")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/festive/fireside"}
                    cldImg={cld.image("work/festive/fireside")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/festive/reindeer"}
                    cldImg={cld.image("work/festive/reindeer")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/festive/hedgehog"}
                    cldImg={cld.image("work/festive/hedgehog")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/festive/snowRabbits"}
                    cldImg={cld.image("work/festive/snowRabbits")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{"display":filter==="dtiys" || filter==="all" ? "inline" : "none"}}>
            <hr/>
            <h4 className={"font"}>DTIYS</h4>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/dtiys/gletidtiys"}
                    cldImg={cld.image("work/dtiys/gletidtiys")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/dtiys/nina"}
                    cldImg={cld.image("work/dtiys/nina")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/dtiys/zovedtiys"}
                    cldImg={cld.image("work/dtiys/zovedtiys")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/dtiys/inkyDinky"}
                    cldImg={cld.image("work/dtiys/inkyDinky")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/dtiys/aliceFlower"}
                    cldImg={cld.image("work/dtiys/aliceFlower")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/dtiys/fawa"}
                    cldImg={cld.image("work/dtiys/fawa")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/dtiys/elsabater"}
                    cldImg={cld.image("work/dtiys/elsabater")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
              <div
                className="col-3 hover"
                style={{"margin":"0 auto", "display":"inline"}}
              >
                <div>
                  <AdvancedImage
                    key={"work/dtiys/mossy"}
                    cldImg={cld.image("work/dtiys/mossy")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </div>
              </div>
            </div>
          </div>
          </div>

        <div style={{"height":"60vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "marginTop":"5%", "paddingBottom":"5vh"}}>
          <div style={{"height":"60vh", "width":"100%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
            <h2 className={"font"}>Like what you see?</h2>
            <div style={{"maxheight":"100%", "padding":"2%"}}>
               <AdvancedImage
                 key={"work/people/strawberry"}
                 cldImg={cld.image("work/people/strawberry")}
                 plugins={[lazyload(), placeholder("predominant-color")]}
                 style={{"width":"40vw", "maxWidth":"80%","maxHeight":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
               />
            </div>
            <div className={"font2"} style={{"width":"80%", "textAlign":"center", "display":"inline-block", "paddingBottom":"5%"}}>
              Check out my Instagram for all my latest posts and reels, including some behind the scenes to how I create my work!
              <br/><br/>
              <a href="../contact" className={"homePageButton"}>
                Get in touch
              </a>
              <br/><br/>
            </div>
          </div>
        </div>
        <PageFooter isTabletOrMobile={props.isTabletOrMobile} />
      </>
    );
  }

  return(
    <>
    {props.isTabletOrMobile ?
      <MyWorkMobile isTabletOrMobile={props.isTabletOrMobile} isPortrait={props.isPortrait}/>
      :
      <MyWorkDesktop isTabletOrMobile={props.isTabletOrMobile} isPortrait={props.isPortrait}/>
    }
    </>
  );
}

export default MyWorkPage;

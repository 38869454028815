import React from 'react';
import { Navbar,Nav} from 'react-bootstrap';
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/base";

const cld = new Cloudinary({
  cloud: {
    cloudName: "dennyjomiller98"
  }
});

export function MobileNav() {
    return(
        <div className="col-md-12">
            <Navbar variant="light" expand="true" sticky="top" collapseOnSelect={true} style={{"zIndex":"0"}}>
            <div class="flex-container">
              <div>
                <span style={{"maxWidth":"15vw"}}>
                  <Navbar.Brand href="../home" style={{"marginLeft":"5%"}}>
                    <div id="instaLogo logo" style={{"maxWidth":"90px", "float":"left"}}>
                        <AdvancedImage
                          key={"gemselfportrait"}
                          cldImg={cld.image("gemselfportrait")}
                          plugins={[lazyload(), placeholder("predominant-color")]}
                          style={{
                            "width":"100%",
                            "height":"10%",
                            "borderRadius":"50%"
                          }}
                        />
                     </div>
                     &nbsp;
                  </Navbar.Brand>
                </span>
              </div>
              <div>
                <div>
                  <Navbar.Text className="mobileNavTitle" style={{"marginLeft":"2%", "marginRight":"1%"}}>
                  <div>Gem Moss Illustration</div>
                  </Navbar.Text>
                </div>
              </div>
              <div style={{"margin-top":"5%", "margin-left":"2vw"}}>
                <div>
                  <span style={{"float": "right", "marginRight": "2vw"}}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className={"custom-toggler"}/>
                  </span>
                </div>
              </div>
            </div>

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <span>
                        <Nav.Link href="/home" className="aLink mobileNavLink">Home</Nav.Link>
                      </span>
                      <hr/>
                      <span>
                        <Nav.Link href="../myWork" className="aLink mobileNavLink">My Work</Nav.Link>
                      </span>
                      <hr/>
                      <span>
                        <Nav.Link href="../about" className="aLink mobileNavLink">About Me</Nav.Link>
                      </span>
                      <hr/>
                      <span>
                        <Nav.Link href="../contact" className="aLink mobileNavLink">Contact</Nav.Link>
                      </span>
                      <hr/>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <br />
        </div>
    );
}

export function PageNav() {
   return(
     <>
      <div className="col-md-12">
          <Navbar variant="light" expand="true" sticky="top" collapseOnSelect={true} style={{"zIndex":"0"}}>
          <div style={{"width":"100vw", "paddingLeft":"1%"}}>
            <span style={{"maxWidth":"20vw"}}>
              <Navbar.Brand href="../home" style={{"marginLeft":"5%"}}>
                <div id="instaLogo logo" style={{"maxWidth":"100px", "float":"left"}}>
                    <AdvancedImage
                      key={"gemselfportrait"}
                      cldImg={cld.image("gemselfportrait")}
                      plugins={[lazyload(), placeholder("predominant-color")]}
                      style={{
                        "width":"100%",
                        "height":"10%",
                        "borderRadius":"50%"
                      }}
                    />
                 </div>
                 &nbsp;
              </Navbar.Brand>
              <Navbar.Text className="navTitle" style={{"marginLeft":"2%", "width":"45vw", "display":"inline-block"}}>Gem Moss Illustration</Navbar.Text>
              <Nav.Link href="/home" className="aLink navLink" style={{"display":"inline-block", "color":"#000000", "maxWidth":"6vw"}}>Home</Nav.Link>
              <Nav.Link href="../myWork" className="aLink navLink" style={{"display":"inline-block", "color":"#000000", "maxWidth":"8vw"}}>My Work</Nav.Link>
              <Nav.Link href="../about" className="aLink navLink" style={{"display":"inline-block", "color":"#000000", "maxWidth":"9vw"}}>About Me</Nav.Link>
              <Nav.Link href="../contact" className="aLink navLink" style={{"display":"inline-block", "color":"#000000", "maxWidth":"5vw"}}>Contact</Nav.Link>
            </span>
          </div>
          </Navbar>
          <br/>
      </div>
     </>
   );
}

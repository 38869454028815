import React from 'react';
import PageFooter from './PageFooter.js';
import {Link} from "react-router-dom";
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/base";

function MainPage(props) {
    const cld = new Cloudinary({
      cloud: {
        cloudName: "dennyjomiller98"
      }
    });

    const MainPageDesktop = () => {
      return(
        <>
          <div style={{"width":"100vw", "height":"100vh", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "backgroundImage":"url(https://res.cloudinary.com/dennyjomiller98/image/upload/v1683483139/fox.jpg)", "backgroundSize":"cover", "backgroundPosition": "center", "backgroundRepeat": "no-repeat"}}>
            <div style={{"width":"60vh", "backgroundColor":"white", "marginLeft":"10vw", "marginTop":"40vh"}}>
              <div style={{"height":"50%", "maxHeight":"50%", "padding":"3%", "textAlign":"center", "boxShadow": "#45454b 0px 0px 3px 3px", "border-radius": "3px"}}>
                <h2 className={"font"}>Hi there!</h2>
                <p className={"font2"}>
                  My name is Gem and I specialise in Children's Illustrations. <br/>
                  You can find all of my latest work right here on my site! Feel free to explore around and let me know what you think!
                </p>
              </div>
            </div>
          </div>

          <div style={{"height":"80vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
            <h3 style={{"marginTop":"5vh"}} className={"font"}>Latest Work:</h3>
            <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"2%"}}>
              <div className="row">
                <div
                  className="col-4"
                  style={{"margin":"0 auto", "display":"inline"}}
                >
                  <Link to="../myWork">
                    <AdvancedImage
                      key={"deer"}
                      cldImg={cld.image("deer")}
                      plugins={[placeholder("predominant-color")]}
                      style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                    />
                  </Link>
                </div>

                <div
                  className="col-4"
                  style={{"margin":"0 auto", "display":"inline"}}
                >
                  <Link to="../myWork">
                    <AdvancedImage
                      key={"bearpink"}
                      cldImg={cld.image("bearpink")}
                      plugins={[placeholder("predominant-color")]}
                      style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                    />
                  </Link>
                </div>

                <div
                  className="col-4"
                  style={{"margin":"0 auto", "display":"inline"}}
                >
                  <Link to="../myWork">
                    <AdvancedImage
                      key={"strawberry"}
                      cldImg={cld.image("strawberry")}
                      plugins={[placeholder("predominant-color")]}
                      style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                    />
                  </Link>
                </div>
              </div>
              <a href="../myWork" className={"homePageButton"}>
                See more work
              </a>
            </div>
          </div>

          <div style={{"height":"100vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
            <div style={{"height":"98.5vh"}}>
              <div style={{"width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <h2 className={"font"}>Get the latest</h2>
                <p className={"font2"}>Check out my Instagram for all my latest posts and reels, including some behind the scenes to how I create my work!</p>
                <a href="https://www.instagram.com/gemmossillustration/" className={"homePageButton"}>
                  View Instagram
                </a>
              </div>
              <div style={{"height":"50vh", "width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <div style={{"maxheight":"100%", "padding":"2%"}}>
                   <AdvancedImage
                     key={"badger"}
                     cldImg={cld.image("badger")}
                     plugins={[lazyload(), placeholder("predominant-color")]}
                    style={{"maxWidth":"99%", "borderRadius":"5px","maxHeight":"100%", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                   />
                </div>
              </div>
            </div>
            <div style={{"height":"98.5vh"}}>
              <div style={{"width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <div style={{"maxheight":"100%"}}>
                   <AdvancedImage
                     key={"basset"}
                     cldImg={cld.image("basset")}
                     plugins={[lazyload(), placeholder("predominant-color")]}
                     style={{"maxWidth":"99%","maxHeight":"100%", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", "borderRadius":"5px"}}
                   />
                </div>
              </div>
              <div style={{"height":"90vh", "width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <h2 className={"font"}>Like what you see?</h2>
                <p className={"font2"}>If you'd like to collaborate, please feel free to get in touch using the link below. I will aim to get back to you ASAP!</p>
                <a href="../contact" className={"homePageButton"}>
                  Get in touch
                </a>
              </div>
            </div>
            <div style={{"height":"98.5vh"}}>
              <div style={{"width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <h2 className={"font"}>Check out my store!</h2>
                <br/>
                <a href="https://www.etsy.com/shop/gemmossillustration/" className={"homePageButton"}>
                  View Etsy
                </a>
              </div>
              <div style={{"height":"100vh", "width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <div style={{"maxheight":"60vh", "padding":"2%"}}>
                   <AdvancedImage
                     key={"rhubarb"}
                     cldImg={cld.image("rhubarb")}
                     plugins={[lazyload(), placeholder("predominant-color")]}
                     style={{"width":"99%", "maxWidth":"99%", "borderRadius":"5px","maxHeight":"100%", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                   />
                </div>
              </div>
              <PageFooter/>
            </div>
          </div>
        </>
      );
    }

    const MainPageMobilePortrait = () => {
      return(
        <>
          <div style={{"width":"80%", "marginLeft":"10%", "backgroundColor":"white", "borderRadius":"3px"}}>
            <div style={{"height":"50%", "maxHeight":"50%", "padding":"1%"}}>
              <h2 className={"font"}>Hi there</h2>
              <br/>
              <p className={"font2"}>
                My name is Gem and I specialise in Children's Illustrations. <br/>
                You can find all of my latest work right here on my site! Feel free to explore around and let me know what you think!
              </p>
            </div>
          </div>
          <div style={{"height":"50vh", "width":"90vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh",  "backgroundImage":"url(https://res.cloudinary.com/dennyjomiller98/image/upload/v1683483139/fox.jpg)", "backgroundSize":"cover", "backgroundPosition": "center", "backgroundRepeat": "no-repeat"}}></div>

          <div style={{"height":"80vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "paddingBottom":"5vh"}}>
            <h2 className={"font"} style={{"marginTop":"5vh"}}>Latest Work:</h2>
            <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
              <div style={{"margin":"0 auto", "display":"inline"}}>
                <Link to="../myWork">
                  <AdvancedImage
                    key={"deer"}
                    cldImg={cld.image("deer")}
                    plugins={[placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </Link>
              </div>
            </div>
            <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
              <div style={{"margin":"0 auto", "display":"inline"}}>
                <Link to="../myWork">
                  <AdvancedImage
                    key={"bearpink"}
                    cldImg={cld.image("bearpink")}
                    plugins={[placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </Link>
              </div>
            </div>
            <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
              <div style={{"margin":"0 auto", "display":"inline"}}>
                <Link to="../myWork">
                  <AdvancedImage
                    key={"strawberry"}
                    cldImg={cld.image("strawberry")}
                    plugins={[placeholder("predominant-color")]}
                    style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                  />
                </Link>
              </div>
            </div>
            <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
              <a href="../myWork" className={"homePageButton"}>
                See more work
              </a>
            </div>
          </div>

          <div style={{"height":"60vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "marginTop":"10%"}}>
            <div style={{"height":"60vh", "width":"100%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
              <h2 className={"font"}>Get the latest</h2>
              <div style={{"maxheight":"100%", "padding":"2%"}}>
                 <AdvancedImage
                   key={"badger"}
                   cldImg={cld.image("badger")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"80%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
              </div>
              <p className={"font2"} style={{"width":"80%", "textAlign":"center", "display":"inline-block", "marginTop":"5%"}}>
                Check out my Instagram for all my latest posts and reels, including some behind the scenes to how I create my work!
              </p>
              <a href="https://www.instagram.com/gemmossillustration/" className={"homePageButton"}>
                View Instagram
              </a>
            </div>
          </div>

          <div style={{"height":"60vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "marginTop":"5%"}}>
            <div style={{"height":"60vh", "width":"100%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
              <h2 className={"font"}>Like what you see?</h2>
              <div style={{"maxheight":"100%", "padding":"2%"}}>
                 <AdvancedImage
                   key={"basset"}
                   cldImg={cld.image("basset")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"80%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
              </div>
              <p className={"font2"} style={{"width":"80%", "textAlign":"center", "display":"inline-block", "marginTop":"5%"}}>
                If you'd like to collaborate, please feel free to get in touch using the link below. I will aim to get back to you ASAP!
              </p>
              <a href="../contact" className={"homePageButton"}>
                Get in touch
              </a>
            </div>
          </div>

          <div style={{"height":"60vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "marginTop":"5%"}}>
            <div style={{"height":"60vh", "width":"100%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
              <h2 className={"font"}>Check out my store!</h2>
              <div style={{"maxheight":"100%", "padding":"2%"}}>
                 <AdvancedImage
                   key={"rhubarb"}
                   cldImg={cld.image("rhubarb")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"80%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
              </div>
              <br/>
              <a href="https://www.etsy.com/shop/gemmossillustration/" className={"homePageButton"}>
                View Etsy
              </a>
            </div>
          </div>
          <PageFooter isTabletOrMobile={props.isTabletOrMobile} />
        </>
      );
    }

    const MainPageMobileLandscape = () => {
      return(
        <>
          <div style={{"paddingBottom":"2%"}}>
            <div style={{"width":"80%", "marginLeft":"10%", "backgroundColor":"white", "borderRadius":"3px"}}>
              <div style={{"height":"50%", "maxHeight":"50%", "padding":"1%"}}>
                <h2 className={"font"}>Hi there</h2>
                <br/>
                <p className={"font2"}>
                  My name is Gem and I specialise in Children's Illustrations. <br/>
                  You can find all of my latest work right here on my site! Feel free to explore around and let me know what you think!
                </p>
              </div>
            </div>
            <div style={{"height":"50vh", "width":"90vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh",  "backgroundImage":"url(https://res.cloudinary.com/dennyjomiller98/image/upload/v1683483139/fox.jpg)", "backgroundSize":"cover", "backgroundPosition": "center", "backgroundRepeat": "no-repeat"}}></div>

            <div style={{"height":"40vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "paddingBottom":"5vh"}}>
              <h2 className={"font"} style={{"marginTop":"5vh"}}>Latest Work:</h2>
              <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
                <div style={{"margin":"0 auto", "display":"inline"}}>
                  <Link to="../myWork">
                    <AdvancedImage
                      key={"deer"}
                      cldImg={cld.image("deer")}
                      plugins={[placeholder("predominant-color")]}
                      style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", "width":"40vw"}}
                    />
                  </Link>
                </div>
              </div>
              <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
                <div style={{"margin":"0 auto", "display":"inline"}}>
                  <Link to="../myWork">
                    <AdvancedImage
                      key={"bearpink"}
                      cldImg={cld.image("bearpink")}
                      plugins={[placeholder("predominant-color")]}
                      style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", "width":"40vw"}}
                    />
                  </Link>
                </div>
              </div>
              <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
                <div style={{"margin":"0 auto", "display":"inline"}}>
                  <Link to="../myWork">
                    <AdvancedImage
                      key={"strawberry"}
                      cldImg={cld.image("strawberry")}
                      plugins={[placeholder("predominant-color")]}
                      style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", "width":"40vw"}}
                    />
                  </Link>
                </div>
              </div>
              <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
                <a href="../myWork" className={"homePageButton"}>
                  See more work
                </a>
              </div>
            </div>

            <div style={{"height":"60vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "marginTop":"5%"}}>
              <div style={{"height":"60vh", "width":"100%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <h2 className={"font"}>Get the latest</h2>
                <div style={{"maxheight":"100%", "padding":"2%"}}>
                   <AdvancedImage
                     key={"badger"}
                     cldImg={cld.image("badger")}
                     plugins={[lazyload(), placeholder("predominant-color")]}
                     style={{"width":"40vw", "maxWidth":"80%","maxHeight":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                   />
                </div>
                <p className={"font2"} style={{"width":"80%", "textAlign":"center", "display":"inline-block", "marginTop":"5%"}}>
                  Check out my Instagram for all my latest posts and reels, including some behind the scenes to how I create my work!

                </p>
                <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
                  <a href="https://www.instagram.com/gemmossillustration/" className={"homePageButton"}>
                    View Instagram
                  </a>
                </div>
              </div>
            </div>

            <div style={{"height":"60vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "marginTop":"5%"}}>
              <div style={{"height":"60vh", "width":"100%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <h2 className={"font"}>Like what you see?</h2>
                <div style={{"maxheight":"100%", "padding":"2%"}}>
                   <AdvancedImage
                     key={"basset"}
                     cldImg={cld.image("basset")}
                     plugins={[lazyload(), placeholder("predominant-color")]}
                     style={{"width":"40vw", "maxWidth":"80%","maxHeight":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                   />
                </div>
                <p className={"font2"} style={{"width":"80%", "textAlign":"center", "display":"inline-block", "marginTop":"5%"}}>
                  If you'd like to collaborate, please feel free to get in touch using the link below. I will aim to get back to you ASAP!
                </p>
                <div style={{"display":"inline-block", "width":"80vw", "maxWidth":"80vw", "paddingBottom":"5%"}}>
                  <a href="../contact" className={"homePageButton"}>
                    Get in touch
                  </a>
                </div>
              </div>
            </div>

            <div style={{"height":"60vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "marginTop":"5%"}}>
              <div style={{"height":"60vh", "width":"100%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <h2 className={"font"}>Check out my store!</h2>
                <div style={{"maxheight":"100%", "padding":"2%"}}>
                   <AdvancedImage
                     key={"rhubarb"}
                     cldImg={cld.image("rhubarb")}
                     plugins={[lazyload(), placeholder("predominant-color")]}
                     style={{"width":"40vw", "maxWidth":"80%","maxHeight":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                   />
                </div>
                <p className="helv" style={{"width":"80%", "textAlign":"center", "display":"inline-block", "marginTop":"5%"}}>
                  <a href="https://www.etsy.com/shop/gemmossillustration/" className={"homePageButton"} >
                    View Etsy
                  </a>
                </p>
              </div>
            </div>
            <br/>
          </div>
          <PageFooter isTabletOrMobile={props.isTabletOrMobile} />
        </>
      );
    }

    return (
      <>
      {props.isTabletOrMobile ?
        <>
        {props.isPortrait ?
          <MainPageMobilePortrait isPortrait={props.isPortrait} isTabletOrMobile={props.isTabletOrMobile}/>
          :
          <MainPageMobileLandscape isPortrait={props.isPortrait} isTabletOrMobile={props.isTabletOrMobile}/>}
        </>
        :
        <MainPageDesktop/>
      }
      </>
    );
}

export default MainPage;

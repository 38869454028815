import React, {useState} from "react";
import PageFooter from './PageFooter.js';
import emailCred from '../emailCreds.js';
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/base";

function ContactPage(props) {

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dennyjomiller98"
    }
  });
    const ContactPageMobile = () => {
      const [enteredEmail, setEnteredEmail] = useState('');
      const [enteredEnquiry, setEnteredEnquiry] = useState('');
      let [isSubmit, setIsSubmit] = useState(false);

        const submitHandler = async (event) => {
          event.preventDefault();
          setIsSubmit(true);

          //reset the values of input fields
          setEnteredEmail('');
          setEnteredEnquiry('');

          //Submit Form / send mail here
          let formValues = {
            email: enteredEmail,
            reply_to: enteredEmail,
            enquiry: enteredEnquiry,
          };

          window.emailjs.init(emailCred.USER_ID);

          window.emailjs.send(emailCred.SERVICE_ID, emailCred.TEMPLATE_ID, formValues)
          .then((result) => {
              let userNotifySuccess = document.getElementById('successMsg');
              userNotifySuccess.style.display='block';
              let userNotifyError = document.getElementById('errorMsg');
              userNotifyError.style.display='none';
              setIsSubmit(false);
          }, (error) => {
            let userNotifyError = document.getElementById('errorMsg');
            userNotifyError.style.display='block';
            let userNotifySuccess = document.getElementById('successMsg');
            userNotifySuccess.style.display='none';
            setIsSubmit(false);
          });
        };

        const emailChangeHandler = (event) => {
          setEnteredEmail(event.target.value);
        };

        const enquiryChangeHandler = (event) => {
          setEnteredEnquiry(event.target.value);
        };
      return (
        <>
        <form id="contactForm" onSubmit={submitHandler} className="pageContent">
          <div>
            <label>Email address</label>
            <input type="email" style={{"borderRadius":"10px"}} className="form-control" id="emailInput" aria-describedby="emailHelp" placeholder="Enter your email here." onChange={emailChangeHandler} value={enteredEmail} required/>
            <small id="emailHelp" className="form-text text-muted"></small>
          </div>
          <div>
            <label>Enquiry</label>
            <textarea type="text" style={{"borderRadius":"10px"}} className="form-control" id="enquiryInput" aria-describedby="enquiryHelp" placeholder="Enter your enquiry here." rows="3" onChange={enquiryChangeHandler} value={enteredEnquiry} required/>
            <small id="enquiryHelp" className="form-text text-muted"></small>
          </div>
          <br/>
          <button className="btn btn-secondary" type="submit" style={{"width":"30%"}}>
              Submit
          </button>
          <br/>
          {isSubmit ?
            <span id="loadingMsg" className="font2" style={{"color":"grey", "display":"block"}}>
              <br/><br/>
              Submitting your request, please wait...
            </span>
           :
            <></>
          }
          <span id="successMsg" className="font2" style={{"color":"green", "display":"none"}}>
            <br/><br/>
            Thanks for your enquiry!
          </span>
          <br/>
          <span id="errorMsg" className="font2" style={{"color":"red", "display":"none"}}>
            An error has occurred - Sorry, we were unable to complete your enquiry! Please try again later or get in touch via <a className="aLink bold" href="mailto:gemmossillustration@gmail.com">gemmossillustration@gmail.com</a>.
          </span>
        </form>
        </>
      );
    }

    const ContactPageDesktop = () => {
      const [enteredEmail, setEnteredEmail] = useState('');
      const [enteredEnquiry, setEnteredEnquiry] = useState('');
      let [isSubmit, setIsSubmit] = useState(false);

        const submitHandler = async (event) => {
          event.preventDefault();
          setIsSubmit(true);

          //reset the values of input fields
          setEnteredEmail('');
          setEnteredEnquiry('');

          //Submit Form / send mail here
          let formValues = {
            email: enteredEmail,
            reply_to: enteredEmail,
            enquiry: enteredEnquiry,
          };

          window.emailjs.init(emailCred.USER_ID);

          window.emailjs.send(emailCred.SERVICE_ID, emailCred.TEMPLATE_ID, formValues)
          .then((result) => {
              let userNotifySuccess = document.getElementById('successMsg');
              userNotifySuccess.style.display='block';
              let userNotifyError = document.getElementById('errorMsg');
              userNotifyError.style.display='none';
              setIsSubmit(false);
          }, (error) => {
            let userNotifyError = document.getElementById('errorMsg');
            userNotifyError.style.display='block';
            let userNotifySuccess = document.getElementById('successMsg');
            userNotifySuccess.style.display='none';
            setIsSubmit(false);
          });
        };

        const emailChangeHandler = (event) => {
          setEnteredEmail(event.target.value);
        };

        const enquiryChangeHandler = (event) => {
          setEnteredEnquiry(event.target.value);
        };

      return (
        <>
          <form id="contactForm" onSubmit={submitHandler} className="pageContent">
            <div>
              <label>Email address</label>
              <input type="email" style={{"borderRadius":"10px"}} className="form-control" id="emailInput" aria-describedby="emailHelp" placeholder="Enter your email here." onChange={emailChangeHandler} value={enteredEmail} required/>
              <small id="emailHelp" className="form-text text-muted"></small>
            </div>
            <div>
              <label>Enquiry</label>
              <textarea type="text" style={{"borderRadius":"10px"}} className="form-control" id="enquiryInput" aria-describedby="enquiryHelp" placeholder="Enter your enquiry here." rows="3" onChange={enquiryChangeHandler} value={enteredEnquiry} required/>
              <small id="enquiryHelp" className="form-text text-muted"></small>
            </div>
            <br/>
            <button className="btn btn-secondary" type="submit" style={{"width":"30%"}}>
              <span>
                Submit
              </span>
            </button>
            <br/>
            {isSubmit ?
              <span id="loadingMsg" className="font2" style={{"color":"grey", "display":"block"}}>
                <br/><br/>
                Submitting your request, please wait...
              </span>
             :
              <></>
            }
            <span id="successMsg" className="font2" style={{"color":"green", "display":"none"}}>
              <br/><br/>
              Thanks for your enquiry!
            </span>
            <br/>
            <span id="errorMsg" className="font2" style={{"color":"red", "display":"none"}}>
              An error has occurred - Sorry, we were unable to complete your enquiry! Please try again later or get in touch via <a className="aLink bold" href="mailto:gemmossillustration@gmail.com">gemmossillustration@gmail.com</a>.
            </span>
          </form>
        </>
      );
    }

    return(
      <>
      {props.isTabletOrMobile ?
        <>
          <div style={{"width":"80%", "marginLeft":"10%", "backgroundColor":"white", "borderRadius":"3px"}}>
            <div style={{"height":"50%", "maxHeight":"50%", "padding":"1%"}}>
              <div style={{"maxheight":"60vh", "padding":"2%"}}>
                <AdvancedImage
                  key={"bearpink"}
                  cldImg={cld.image("bearpink")}
                  plugins={[lazyload(), placeholder("predominant-color")]}
                 style={{"maxWidth":"70%","maxHeight":"100%"}}
                />
              </div>
              <h2 className={"font"}>Let's Work Together!</h2>
              <br/>
              <div style={{"width":"80%", "display":"inline-block"}}>
                <p className="font2" style={{"maxWidth":"80vw", "display":"inline-block"}}>
                  Want to work together or just want to say hello? Feel free to email me at <a className="aLink bold" href="mailto:gemmossillustration@gmail.com">gemmossillustration@gmail.com</a>, or complete the following form below.
                </p>
                <ContactPageMobile isTabletOrMobile={props.isTabletOrMobile} isPortrait={props.isPortrait}/>
              </div>
            </div>
          </div>
          <PageFooter isTabletOrMobile={props.isTabletOrMobile} isPortrait={props.isPortrait}/>
        </>
        :
        <>
        <div style={{"width":"100vw", "height":"30vh", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "backgroundImage":"url(https://res.cloudinary.com/dennyjomiller98/image/upload/v1683561210/rocket.jpg)", "backgroundSize":"cover", "backgroundPosition": "center", "backgroundRepeat": "no-repeat"}}></div>
          <div style={{"height":"100vh", "width":"100vw", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
            <div style={{"height":"98.5vh"}}>

              <div style={{"height":"100vh", "width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                <div style={{"maxheight":"60vh", "paddingTop":"10vh"}}>
                  <AdvancedImage
                    key={"bearpink"}
                    cldImg={cld.image("bearpink")}
                    plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"50%","maxHeight":"100%"}}
                  />
                </div>
              </div>
              <div style={{"width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "paddingRight":"10vw", "paddingTop":"20vh", "float":"right"}}>
              <h2 className={"font"}>Let's Work Together!</h2>
                <div style={{"width":"80%", "display":"inline-block"}}>
                  <p className="font2" style={{"maxWidth":"80vw", "display":"inline-block"}}>
                    Want to work together or just want to say hello? Feel free to email me at <a className="aLink bold" href="mailto:gemmossillustration@gmail.com">gemmossillustration@gmail.com</a>, or complete the following form below.
                  </p>
                  <ContactPageDesktop isTabletOrMobile={props.isTabletOrMobile} isPortrait={props.isPortrait}/>
                </div>
              </div>
              <PageFooter/>
            </div>
          </div>
        </>
      }
      </>
    );
}

export default ContactPage;

import React from 'react';
import PageFooter from './PageFooter.js';
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/base";
import { saveAs } from 'file-saver';

function AboutPage(props) {

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dennyjomiller98"
    }
  });
  var JSZip = require("jszip");
  const downloadPortfolio = async () => {
    let porfolioImgs = [
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/rhubarb.jpg',
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/strawberry.jpg',
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/redRidingHood.jpg',
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/aliceInWonderland.jpg',
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/badger.jpg',
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/deer.jpg',
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/reindeer.jpg',
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/coral.jpg',
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/mouse.jpg',
      'https://res.cloudinary.com/dennyjomiller98/image/upload/v1667860597/portfolio/snail.jpg',
    ];

    let photoZip = new JSZip();
    for (let i = 0; i < porfolioImgs.length; i++) {
      const result = await fetch(porfolioImgs[i]);
      let blob = result.blob();
      photoZip.file(`gem_moss_${i+1}.jpg`, blob);
    }

    photoZip.generateAsync({type:"blob"})
      .then(function(content) {
        saveAs(content, 'gem_moss_portfolio.zip');
      });
  };

  const AboutMobile = () => {
    return (
      <>
        <div style={{"height":"80vh"}}>
          <h3 className="pageTitle">About Me</h3>
          <br/>
          <div style={{"maxheight":"100%", "padding":"2%"}}>
            {props.isPortrait ?
              <>
                <AdvancedImage
                  key={"gemselfportrait"}
                  cldImg={cld.image("gemselfportrait")}
                  plugins={[lazyload(), placeholder("predominant-color")]}
                  style={{"width":"40vw", "maxWidth":"80%","maxHeight":"100%"}}
                />
                <div style={{"width":"80vw", "display":"inline-block", "textAlign":"center"}}>
                  <p className="font2">
                    I am an illustrator and collage artist based in Chesire. My work heavily focuses on animals and
                    more recently I have discovered a fascination for the hidden world of insects and the integral roles
                    they play for our planet. I am interested in taking animal conservation topics and turning them into
                    introductory topics for children. By raising awareness from such an early age, we can make the
                    youth of today more compassionate and better prepare them for the consequences of actions from
                    today.
                    <br/><br/>
                    I look to use storytelling, relatable characters and a mix of illustration and collage to engage my
                    audience with my work.
                    <br/><br/>
                    I recently completed my Masters in Illustration at the University of Gloucestershire which is where I
                    also completed my bachelor’s with a first‐class Illustration Degree.
                    <br/> <br/>
                  </p>
                  <hr/>
                </div>
              </>
              :
              <>
                <div style={{"height":"100vh"}}>
                  <div style={{"height":"50vh", "width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
                    <div style={{"maxheight":"100%", "padding":"2%"}}>
                       <AdvancedImage
                         key={"gemselfportrait"}
                         cldImg={cld.image("gemselfportrait")}
                         plugins={[lazyload(), placeholder("predominant-color")]}
                        style={{"maxWidth":"70%","maxHeight":"100%"}}
                       />
                    </div>
                  </div>
                  <div style={{"width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "float":"right"}}>
                    <p className="font2">
                      I am an illustrator and collage artist based in Chesire. My work heavily focuses on animals and
                      more recently I have discovered a fascination for the hidden world of insects and the integral roles
                      they play for our planet. I am interested in taking animal conservation topics and turning them into
                      introductory topics for children. By raising awareness from such an early age, we can make the
                      youth of today more compassionate and better prepare them for the consequences of actions from
                      today.
                      <br/><br/>
                      I look to use storytelling, relatable characters and a mix of illustration and collage to engage my
                      audience with my work.
                      <br/><br/>
                      I recently completed my Masters in Illustration at the University of Gloucestershire which is where I
                      also completed my bachelor’s with a first‐class Illustration Degree.
                    </p>
                  </div>
                </div>
                <hr/>
              </>
            }
             <div style={{"width":"80vw", "display":"inline-block", "textAlign":"center"}}>
              <h3 className="font">Portfolio</h3>
                <button className={"homePageButton font"} onClick={downloadPortfolio}>
                  Download
                </button>
               <div className="font2" style={{"textAlign":"center", "width":"80vw", "maxWidth":"80vw", "display":"inline-block", "paddingBottom":"10vh", "paddingTop":"5vh"}}>
                 <div className="row">
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/rhubarb"}
                       cldImg={cld.image("portfolio/rhubarb")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/strawberry"}
                       cldImg={cld.image("portfolio/strawberry")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                 </div>
                 <div className="row">
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/redRidingHood"}
                       cldImg={cld.image("portfolio/redRidingHood")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/aliceInWonderland"}
                       cldImg={cld.image("portfolio/aliceInWonderland")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                 </div>
                 <div className="row">
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/badger"}
                       cldImg={cld.image("portfolio/badger")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/deer"}
                       cldImg={cld.image("portfolio/deer")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                 </div>
                 <div className="row">
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/reindeer"}
                       cldImg={cld.image("portfolio/reindeer")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/coral"}
                       cldImg={cld.image("portfolio/coral")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                 </div>
                 <div className="row">
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/mouse"}
                       cldImg={cld.image("portfolio/mouse")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                   <div
                     className="col-6"
                     style={{"margin":"0 auto", "display":"inline"}}
                   >
                     <AdvancedImage
                       key={"portfolio/snail"}
                       cldImg={cld.image("portfolio/snail")}
                       plugins={[lazyload(), placeholder("predominant-color")]}
                       style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                     />
                   </div>
                 </div>
               </div>
             </div>
          </div>
          <PageFooter isTabletOrMobile={props.isTabletOrMobile} isPortrait={props.isPortrait}/>
        </div>
      </>
    );
  }

  const AboutDesktop = () => {
    return(
      <>
      <div style={{"height":"80vh"}}>
        <h3 className="pageTitle">About Me</h3>
        <br/>
        <div style={{"maxheight":"100%", "padding":"2%"}}>
          <div style={{"height":"50vh"}}>
            <div style={{"height":"50vh", "width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh"}}>
              <div style={{"maxheight":"100%", "padding":"2%"}}>
                 <AdvancedImage
                   key={"gemselfportrait"}
                   cldImg={cld.image("gemselfportrait")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                  style={{"maxWidth":"50%","maxHeight":"100%"}}
                 />
              </div>
            </div>
            <div style={{"width":"50%", "maxWidth":"100vw", "textAlign":"center", "display":"inline-block", "maxHeight":"100vh", "float":"right", "padding":"5%"}}>
              <p className="font2">
                I am an illustrator and collage artist based in Chesire. My work heavily focuses on animals and
                more recently I have discovered a fascination for the hidden world of insects and the integral roles
                they play for our planet. I am interested in taking animal conservation topics and turning them into
                introductory topics for children. By raising awareness from such an early age, we can make the
                youth of today more compassionate and better prepare them for the consequences of actions from
                today.
                <br/><br/>
                I look to use storytelling, relatable characters and a mix of illustration and collage to engage my
                audience with my work.
                <br/><br/>
                I recently completed my Masters in Illustration at the University of Gloucestershire which is where I
                also completed my bachelor’s with a first‐class Illustration Degree.
              </p>
            </div>
          </div>
          <hr/>
         <div style={{"width":"80vw", "display":"inline-block", "textAlign":"center"}}>
          <h3 className="font">Portfolio</h3>
            <button className={"homePageButton font"} onClick={downloadPortfolio}>
              Download
            </button>
           <div className="font2" style={{"textAlign":"center", "width":"80vw", "maxWidth":"80vw", "display":"inline-block", "paddingBottom":"10vh", "paddingTop":"5vh"}}>
             <div className="row">
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/rhubarb"}
                   cldImg={cld.image("portfolio/rhubarb")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/strawberry"}
                   cldImg={cld.image("portfolio/strawberry")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
             </div>
             <div className="row">
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/redRidingHood"}
                   cldImg={cld.image("portfolio/redRidingHood")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/aliceInWonderland"}
                   cldImg={cld.image("portfolio/aliceInWonderland")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
             </div>
             <div className="row">
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/badger"}
                   cldImg={cld.image("portfolio/badger")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/deer"}
                   cldImg={cld.image("portfolio/deer")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
             </div>
             <div className="row">
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/reindeer"}
                   cldImg={cld.image("portfolio/reindeer")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/coral"}
                   cldImg={cld.image("portfolio/coral")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
             </div>
             <div className="row">
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/mouse"}
                   cldImg={cld.image("portfolio/mouse")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
               <div
                 className="col-6"
                 style={{"margin":"0 auto", "display":"inline"}}
               >
                 <AdvancedImage
                   key={"portfolio/snail"}
                   cldImg={cld.image("portfolio/snail")}
                   plugins={[lazyload(), placeholder("predominant-color")]}
                   style={{"maxWidth":"100%", "borderRadius":"5px", "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                 />
               </div>
             </div>
           </div>
         </div>
      </div>
      <PageFooter isTabletOrMobile={props.isTabletOrMobile} isPortrait={props.isPortrait}/>
    </div>
  </>
  );
  }

  return(
    <>
    {props.isTabletOrMobile ?
      <AboutMobile isTabletOrMobile={props.isTabletOrMobile} isPortrait={props.isPortrait}/>
      :
      <AboutDesktop isTabletOrMobile={props.isTabletOrMobile} isPortrait={props.isPortrait}/>
    }
    </>
  );
}

export default AboutPage;

import React from "react";
import Img from 'react-cloudinary-lazy-image';

function PageFooter(props) {
  const PageFooterDesktop = () => {
    return (
      <>
        <div style={{"textAlign":"center", "background":"#3c3c3c", "position": "absolute", "width":"100vw", "padding":"1%", "maxWidth":"100vw"}} className="pageFooter">
          <p>
          All Rights Reserved &#169; Copyright 2024 Gem Moss
          </p>
        </div>
        <span style={{"display": "inline", "maxWidth":"10vw"}}>
          <a href="https://www.etsy.com/shop/gemmossillustration" className="aLink navLink" style={{"width":"40px", "maxWidth":"40px", "float":"right", "marginRight":"3vw"}}>
             <Img
                  cloudName={'dennyjomiller98'}
                  imageName={'etsySimple'}
                  fluid={{
                      maxWidth: 500,
                      maxHeight: 500,
                  }}
              />
          </a>
          <a href="https://www.instagram.com/gemmossillustration/" className="aLink navLink" style={{"width":"40px", "maxWidth":"40px", "float":"right", "marginRight":"3vw"}}>
            <Img
                 cloudName={'dennyjomiller98'}
                 imageName={'instagramSimple'}
                 fluid={{
                     maxWidth: 500,
                     maxHeight: 500,
                 }}
             />
          </a>
        </span>
      </>
    );
  }

  const PageFooterMobile = () => {
    return (
      <>
        <div style={{"textAlign":"center", "background":"#3c3c3c", "position": "absolute", "width":"100vw", "padding":"1%"}} className="pageFooter">
          <p style={{"width":"70%"}}>
            All Rights Reserved &#169; Copyright 2024 Gem Moss
          </p>
        </div>
        <span style={{"display": "inline", "maxWidth":"10vw"}}>
          <a href="https://www.etsy.com/shop/gemmossillustration" className="aLink navLink" style={{"width":"40px", "maxWidth":"30px", "float":"right", "marginRight":"5vw"}}>
             <Img
                  cloudName={'dennyjomiller98'}
                  imageName={'etsySimple'}
                  fluid={{
                      maxWidth: 500,
                      maxHeight: 500,
                  }}
              />
          </a>
          <a href="https://www.instagram.com/gemmossillustration/" className="aLink navLink" style={{"width":"40px", "maxWidth":"30px", "float":"right", "marginRight":"5vw"}}>
            <Img
                 cloudName={'dennyjomiller98'}
                 imageName={'instagramSimple'}
                 fluid={{
                     maxWidth: 500,
                     maxHeight: 500,
                 }}
             />
          </a>
        </span>
      </>
    );
  }

  return (
    <>
    {props.isTabletOrMobile ?
      <PageFooterMobile/>
      :
      <PageFooterDesktop/>
    }
    </>
  );
}

export default PageFooter;
